@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes slide-up {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes scale-up {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.staggered-animation {
  animation: scale-up 0.6s ease-out forwards;
  opacity: 1; /* Make sure the element is visible */
}

.animate-slide-up {
  animation: slide-up 0.8s ease-out forwards;
}

.animate-text {
  animation: fade-in 2s ease-out forwards;
}

.staggered-animation {
  animation: scale-up 0.6s ease-out forwards;
  opacity: 1; /* Ensure visibility */
}

.animate-card {
  animation: scale-up 0.6s ease-out forwards;
}

.staggered-animation {
  animation: scale-up 1ms ease-out forwards;
  opacity: 1; /* Ensure visibility */
}

.animate-card {
  animation: scale-up 0.6s ease-out forwards;
  opacity: 1; /* Ensure visibility */
}
